<template>
  <v-sheet class="customer" style="height: calc(100vh - 340px)">
    <v-flex v-if="primary_checkbox" class="my-auto py-0 d-flex justify-content-between">
      <p for="segment_name" class="custom-form-label" style="font-weight: 600">
        Contact Person ({{ contactPersons.length }})
      </p>
      <v-btn
        :disabled="pageLoading"
        :loading="pageLoading"
        class="white--text mr-2"
        depressed
        color="blue darken-4"
        tile
        v-on:click="addContactPerson()"
      >
        ADD CONTACT PERSON
      </v-btn>
    </v-flex>

    <v-simple-table fixed-header class="bt-table table_height_sales">
      <template v-slot:default>
        <thead>
          <tr>
            <th>TITLE</th>
            <th>FIRST NAME</th>
            <th>LAST NAME</th>
            <th>DISPLAY NAME</th>
            <th>MOBILE NUMBER</th>
            <th>DID</th>
            <th>EMAIL</th>
            <th>DESIGNATION</th>

            <th v-if="routes != 'supplier-detail'">Action</th>
          </tr>
        </thead>
        <tbody v-if="!pageLoading">
          <template v-if="contactPersons.length">
            <tr v-for="(row, index) in contactPersons" :key="index">
              <!-- <td class="simple-table-td">{{ index + 1 }}</td> -->
              <td class="simple-table-td text-capitalize">{{ row.title }}.</td>
              <td class="simple-table-td">{{ row.first_name }}</td>
              <td class="simple-table-td">{{ row.last_name }}</td>
              <td class="simple-table-td">{{ row.display_name }}</td>
              <td class="simple-table-td">{{ row.primary_phone }}</td>
              <td class="simple-table-td">{{ row.d_id }}</td>
              <td class="simple-table-td">{{ row.primary_email }}</td>
              <td class="simple-table-td">{{ row.designation }}</td>

              <td v-if="routes != 'supplier-detail'" class="simple-table-td">
                <v-btn
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  v-on:click="updateContactPerson(row)"
                >
                  <v-icon left>mdi-pencil</v-icon>
                </v-btn>
                <template v-if="index > 0">
                  <v-menu
                    top
                    v-model="row.menu"
                    content-class="delete-small-confirm"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mx-2"
                        color="red lighten-1"
                        dark
                        fab
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small dark> mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <DeleteSmallConfirm
                      :message="`Are you sure, you want to delete ${row.display_name} ?`"
                      v-on:cancel="row.menu = false"
                      v-on:success="removeContactPerson(index)"
                    ></DeleteSmallConfirm>
                  </v-menu>
                </template>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td colspan="9">
                <p class="m-0 text-center">
                  <img
                    width="30"
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image mr-4"
                  />
                  Uhh... There are no contact person at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot v-else>
          <tr v-for="idr in 9" :key="idr">
            <td v-for="idk in 9" :key="idk">
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
    <v-layout v-if="customers.length" class="light-border-top mt-4">
      <v-flex md6>
        <label class="btx-label p-4"
          >Showing {{ showingFrom }} to {{ showingTo }} of {{ totalRows }} entries</label
        >
      </v-flex>
      <v-flex md6>
        <v-pagination
          color="blue darken-4"
          v-model="currentPage"
          :length="pageLimit"
          :total-visible="7"
        ></v-pagination>
      </v-flex>
    </v-layout>

    <Dialog :dialog="dialog" v-if="dialog">
      <template v-slot:title>
        {{ dialogTitle }}
      </template>
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <v-form
            ref="secondStep"
            v-model="secondStepValid"
            lazy-validation
            v-on:submit.stop.prevent="appendContactPerson"
          >
            <v-row>
              <v-col md="12">
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="voucher-name" class="btx-label mt-2 required">Title</label>
                  </v-flex>
                  <v-flex md8>
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPerson.title"
                      :rules="[vrules.required(contactPerson.title, 'title')]"
                      :class="{ required: !contactPerson.title }"
                      id="primary"
                      placeholder="Title"
                    ></TextInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="last-name" class="btx-label mt-2 required">First Name</label>
                  </v-flex>
                  <v-flex md8>
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPerson.first_name"
                      :rules="[vrules.required(contactPerson.first_name, 'First Name')]"
                      :class="{ required: !contactPerson.first_name }"
                      id="first-name"
                      placeholder="First Name"
                    ></TextInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="last-name" class="btx-label mt-2 required">Last Name</label>
                  </v-flex>
                  <v-flex md8>
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPerson.last_name"
                      :rules="[vrules.required(contactPerson.last_name, 'Last Name')]"
                      :class="{ required: !contactPerson.last_name }"
                      id="last-name"
                      placeholder="Last Name"
                    ></TextInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="minimum-order-amount" class="btx-label mt-2 required"
                      >Display Name</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPerson.display_name"
                      :rules="[vrules.required(contactPerson.display_name, 'Display Name')]"
                      :class="{ required: !contactPerson.display_name }"
                      id="display-name"
                      placeholder="Display Name"
                    ></TextInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="minimum-order-amount" class="btx-label mt-2 required"
                      >Mobile Number</label
                    >
                  </v-flex>
                  <v-flex md8>
                    <PhoneTextField
                      class="mt-3"
                      required
                      v-model="contactPerson.primary_phone"
                      placeholder="Phone"
                      hide-details
                      dense
                    >
                    </PhoneTextField>
                    <!-- <PhoneInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPerson.primary_phone"
                      :rules="[vrules.required(contactPerson.primary_phone, 'Mobile No')]"
                      :class="{ required: !contactPerson.primary_phone }"
                      id="mobile-no"
                      v-on:paste="(e) => onPaste(e)"

                      placeholder="Mobile no."
                    ></PhoneInput> -->
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="expiry-date" class="btx-label mt-2">DID</label>
                  </v-flex>
                  <v-flex md8>
                    <!-- <PhoneInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPerson.d_id"
                    
                      id="mobile-no"
                      v-on:paste="(e) => onPaste(e)"

                      placeholder="Mobile no."
                    ></PhoneInput> -->
                    <PhoneTextField
                      class="mt-3"
                      v-model="contactPerson.d_id"
                      placeholder="Phone"
                      hide-details
                      dense
                    >
                    </PhoneTextField>
                    <!-- <TextInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPerson.d_id"
                      id="did"
                      placeholder="Did"
                    ></TextInput> -->
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="expiry-date" class="btx-label mt-2 required">Email</label>
                  </v-flex>
                  <v-flex md8>
                    <EmailInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model="contactPerson.primary_email"
                      :rules="[vrules.required(contactPerson.primary_email, 'Email')]"
                      :class="{ required: !contactPerson.primary_email }"
                      id="email"
                      placeholder="Email"
                    ></EmailInput>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex md4 class="my-auto">
                    <label for="expiry-date" class="btx-label mt-2">Designation</label>
                  </v-flex>
                  <v-flex md8>
                    <!-- :loading="loading" -->
                    <!-- :disabled="disabled" -->
                    <SelectInput
                      id="designation"
                      hide-details
                      item-text="text"
                      :items="['Manager', 'Director', 'Seo']"
                      v-model="contactPerson.designation"
                      placeholder="Designation"
                    ></SelectInput>
                  </v-flex>
                </v-layout>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading || !secondStepValid"
          :loading="pageLoading"
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click="appendContactPerson()"
        >
          Save
        </v-btn>
        <!-- {{ contactPerson }} -->
        <v-btn
          v-if="!contactPerson.id"
          :disabled="pageLoading"
          depressed
          tile
          @click="dialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import { GET_CUSTOMERS, GET_TEMPLATE_LIST } from "@/core/lib/customer.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import SelectInput from "@/view/components/SelectInput";
import { cloneDeep } from "lodash";
// import PhoneTemplate from "@/view/components/Phone";
// import PhoneInput from "@/view/components/PhoneInput";

// import {
//   SET_SEARCH_TITLE,
//   SET_SEARCH_MODEL,
//   SET_SEARCH_BAR,
//   BEFORE_DESTROY,
// } from "@/core/services/store/listing.module";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { GET_AVAILABLE_VOUCHERS, GET_COLLECTED_VOUCHERS } from "@/core/lib/common.lib";
import PhoneTextField from "@/view/components/PhoneTextField";

export default {
  name: "sales-update",
  title: "Listing Sales",
  props: {
    primary_checkbox: {
      type: Boolean,
      default: false,
    },
    employeeEdit: {
      type: Boolean,
      default: false,
    },
    persons: {
      type: Array,
      default: () => {
        return [];
      },
    },
    cpersons: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialog: false,
      formValid: true,
      templateList: [{ text: "First", value: "first" }],
      template: "first",
      dialogTitle: "ADD CONTACT PERSON",
      filterType: "all",
      filterDateRange: null,
      editAllData: {},
      firstStepValid: true,

      speedDial: [],
      customers: [],
      secondStepValid: false,
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      routes: null,
      deleteDialog: false,
      customerId: null,
      orderDialog: false,
      editPersonUUID: 0,
      rOrderId: 0,
      rOrderCode: null,
      rLoading: false,
      rVouchers: [],
      redeemDialog: false,
      cOrderId: 0,
      cOrderCode: null,
      cLoading: false,
      cVouchers: [],
      collectDialog: false,
      allCollectedDialog: false,
      salestableHeading: [
        { title: "ACTION", status: true },
        { title: "PRIMARY", status: true },
        { title: "SALUTATION", status: true },
        { title: "FIRST NAME", status: true },
        { title: "LAST NAME", status: true },
        { title: "DISPLAY NAME", status: true },
        { title: "MOBILE NUMBER", status: true },
        { title: "DID", status: true },
        { title: "EMAIL", status: true },
        { title: "DESIGNATION", status: true },
      ],
      salesSummaryDate: [
        {
          action: { value: "", key: "ACTION" },
          primary: { value: "PRIMARY", key: "PRIMARY" },
          salutation: { value: "Mr.", key: "SALUTATION" },
          first_name: { value: "SANS", key: "FIRST NAME" },
          last_name: { value: "SENAROY", key: "LAST NAME" },
          display_name: { value: "Marqure", key: "DISPLAY NAME" },
          mobile_number: { value: "no mobile number", key: "MOBILE NUMBER" },
          did: { value: "+65 45675666", key: "DID" },
          email: { value: "marqure@gmail.com", key: "EMAIL" },
          designation: { value: "Manager", key: "DESIGNATION" },
        },
        {
          action: { value: "", key: "ACTION" },
          primary: { value: "PRIMARY", key: "PRIMARY" },
          salutation: { value: "Mr.", key: "SALUTATION" },
          first_name: { value: "Abinash", key: "FIRST NAME" },
          last_name: { value: "no last name", key: "LAST NAME" },
          display_name: { value: "Marqure", key: "DISPLAY NAME" },
          mobile_number: { value: "no mobile number", key: "MOBILE NUMBER" },
          did: { value: "", key: "DID" },
          email: { value: "marqure@gmail.com", key: "EMAIL" },
          designation: { value: "Director", key: "DESIGNATION" },
        },
      ],
      dragStartIndex: null,
      dragOverIndex: null,
      contactPerson: {
        id: null,
        title: null,
        first_name: null,
        last_name: null,
        display_name: null,
        primary_phone: null,
        d_id: null,
        primary_email: null,
        designation: null,
      },
      contactPersons: [],
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
    };
  },
  watch: {
    currentPage() {
      this.getSummary();
    },
    listingSearch() {
      this.getSummary();
    },
    filterDateRange() {
      if (this.filterDateRange && this.filterDateRange.length > 1) {
        this.filterType = "all";
        this.getSummary();
      }
    },
    persons: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log(param, "persons");
        this.contactPersons = param;
      },
    },
    cpersons: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log(param, "persons");
        this.contactPersons = param;
      },
    },
    contactPersons: {
      deep: true,
      immediate: true,
      handler(param) {
        let result = cloneDeep(param);
        this.$emit("saveContactPerson", result);
      },
    },
  },
  methods: {
    onPaste(e) {
      let coppied = e.clipboardData.getData("Text");
      let preVal = this.contactPerson.primary_phone;
      let finalval = String(preVal) + String(coppied);
      if (Number(finalval.length) > 13) {
        let trimValue = finalval.substring(0, 14);
        this.contactPerson.primary_phone = trimValue;
        e.preventDefault();
      }
    },
    updateContactPerson(param) {
      console.log(param, "param");

      if (param?.first_name) {
        this.dialogTitle = "Update Contact Person";
      } else {
        this.dialogTitle = "Add Contact Person";
      }

      //console.log(param)
      this.editPersonUUID = param.id;
      this.editAllData = param;
      this.contactPerson = param;
      this.updateSelf = true;
      this.dialog = true;
    },
    appendContactPerson() {
      if (!this.$refs.secondStep?.validate()) {
        return false;
      }
      if (this.editPersonUUID == 0) {
        this.contactPersons.push({
          menu: false,
          id: this.contactPerson.id,
          title: this.contactPerson.title,
          first_name: this.contactPerson.first_name,
          last_name: this.contactPerson.last_name,
          display_name: this.contactPerson.display_name,
          primary_phone: this.contactPerson.primary_phone,
          d_id: this.contactPerson.d_id,
          primary_email: this.contactPerson.primary_email,
          position: this.contactPerson.position,
          designation: this.contactPerson.designation,
        });
        this.contactPerson = {
          id: null,
          title: null,
          first_name: null,
          last_name: null,
          display_name: null,
          primary_phone: null,
          d_id: null,
          primary_email: null,
          position: null,
          designation: null,
        };
      } else {
        this.contactPerson = {
          id: null,
          title: null,
          first_name: null,
          last_name: null,
          display_name: null,
          primary_phone: null,
          d_id: null,
          primary_email: null,
          position: null,
          designation: null,
        };
      }
      this.dialog = false;
    },

    addContactPerson() {
      this.editPersonUUID = 0;
      this.dialog = true;
      this.contactPerson = {
        id: null,
        title: null,
        first_name: null,
        last_name: null,
        display_name: null,
        primary_phone: null,
        d_id: null,
        primary_email: null,
        position: null,
        designation: null,
      };
      this.dialogTitle = "Add Contact Person";

      console.log(this.contactPerson, "vv");
    },
    getColValue(val) {
      if (val.includes(" ")) {
        return val.split(" ").join("_").toLowerCase();
      } else {
        return val.toLowerCase();
      }
    },
    dragstart(index) {
      this.dragStartIndex = index;
    },
    dragover(index) {
      this.dragOverIndex = index;
    },
    dragEnd() {
      const dragStartElement = this.salestableHeading[this.dragStartIndex];
      this.salestableHeading.splice(this.dragStartIndex, 1);
      this.salestableHeading.splice(this.dragOverIndex, 0, dragStartElement);
    },
    drastart(index) {
      this.dragging_element_index = index;
    },
    filterTypeChange() {
      this.filterDateRange = null;
      // this.getSummary();
    },
    saveOrder() {
      this.customerId = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    addOrder({ id }) {
      this.customerId = id;
      this.orderDialog = true;
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.saveOrder();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.saveOrder();
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    routeToUpdate(id) {
      this.$router.push({
        name: "sales-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    routeToDetail(id) {
      this.$router.push({
        name: "sales-detail",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    deleteConfirm({ barcode, id }) {
      this.deleteText = barcode;
      this.deleteEndpoint = `customer/${id}`;
      this.deleteDialog = true;
    },
    async getSummary() {
      try {
        let form = {
          current_page: this.currentPage,
          search: this.listingSearch,
          filter_type: this.filterType,
        };
        if (this.filterDateRange) {
          const dateRange = {
            start_date: this.filterDateRange[0],
            end_date: this.filterDateRange[1],
          };
          form = { ...form, ...dateRange };
        }
        this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_CUSTOMERS(form);
        this.customers = rows;
        this.pageLimit = totalPages;
        this.showingFrom = showingFrom;
        this.showingTo = showingTo;
        this.totalRows = totalRows;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
    resetSearch() {
      this.$router
        .replace({
          query: {
            t: new Date().getTime(),
          },
        })
        .then(() => {});
    },
    async getTemplateListing() {
      try {
        this.pageLoading = true;
        console.log("getTemplateListing");
        const data = await GET_TEMPLATE_LIST();
        console.log("data", data);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  async mounted() {
    this.getSummary();
    console.log(this.$route.name);
    this.routes = this.$route.name;
    // await this.getTemplateListing();
  },
  beforeDestroy() {},
  components: {
    Dialog,
    TextInput,
    EmailInput,
    SelectInput,
    // PhoneInput,
    PhoneTextField,
  },
  computed: {
    ...mapGetters(["listingSearch"]),
  },
};
</script>

<style>
.advace_list_menu {
  border-bottom: 0.2px solid rgba(0, 0, 0, 0.158);
  display: flex;
  justify-content: space-between;
}

.table_height_sales .v-data-table__wrapper {
  height: calc(100vh - 340px);
}

::-webkit-scrollbar {
  height: 10px;
}

tr:nth-child(odd) {
  background-color: rgb(199 201 53 / 15%);
}
</style>
