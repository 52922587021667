<template>
  <v-sheet class="customer" id="customer" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="8" class="my-auto py-0"
        ><h1 class="m-0 text-capitalize">
          {{ customer.first_name }}
          <v-chip class="" color="#e0e0e003" label text-color="white"></v-chip>
        </h1>
      </v-col>
      <v-col md="8" class="my-auto py-0">
        <v-btn class="mr-4 btn btn-danger" v-if="customer && customer?.initial_pending">
          Balance : {{ formatMoney(customer?.initial_pending) }}</v-btn
        >
        <v-btn class="mr-4 btn btn-danger" v-else>
          Balance: 0</v-btn
        >
        <v-btn class="mr-4 btn btn-info" v-if="customer && customer?.total">
          Total Amount : {{ formatMoney(customer?.total) }}</v-btn
        >

        <v-btn class="mr-4 btn btn-success" v-if="customer">
          Paid : {{ formatMoney(customer?.final_paid + customer?.initial_paid) }}</v-btn
        >
      </v-col>
      <v-col md="4" class="text-right">
        <!-- <v-btn class="mr-4 btn btn-danger " > Outstanding Amount : {{ formatMoney(customer.initial_pending) }}</v-btn>
        <v-btn class="mr-4 btn btn-info " > Total  Amount : {{ formatMoney(customer.total) }}</v-btn>

        <v-btn class="mr-4 btn btn-danger " > Paid : {{ formatMoney(customer.final_paid + customer.initial_paid) }}</v-btn> -->

        <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          v-on:click.stop.prevent="routeToUpdate(customer.id)"
        >
          <v-icon small class="me-1">mdi-pencil</v-icon> Edit
        </v-btn>

        <v-btn class="ml-2" depressed tile v-on:click="goBack()"> Back </v-btn>
      </v-col>
    </v-row>
    <v-row style="background-color: rgb(240, 248, 255)" class="align-center">
      <v-flex class="d-flex justify-content-between align-center">
        <v-col>
          <ImageTemplate
            :src="customer?.supplier_image?.url"
            :maxWidth="100"
            :maxHeight="100"
            :aspectRatio="1"
            :contain="false"
            customClass="mr-2"
            circle
          ></ImageTemplate>
        </v-col>
        <v-col md="4" class="">
          <p class="mb-1">
            <span class="me-2" style="font-weight: bold">Display Name:</span>
            <ValueTemplate
              class="text-capitalize"
              :value="customer.display_name"
              title="Company Phone No."
            ></ValueTemplate>
          </p>
          <p class="mb-1">
            <span class="me-2" style="font-weight: bold">Company Email:</span>
            <ValueTemplate
              :value="customer.company_email"
              class="text-capitalize"
              title="Company Phone No."
            ></ValueTemplate>
          </p>
          <p>
            <span class="me-2" style="font-weight: bold">Created By:</span>
            <ValueTemplate :value="customer.user_name" title=""></ValueTemplate>
          </p>
        </v-col>
        <v-col md="4">
          <p class="mb-1">
            <span class="me-2" style="font-weight: bold">Category:</span>
            <ValueTemplate
              class="text-capitalize"
              :value="customer.category"
              title="Company Phone No."
            ></ValueTemplate>
          </p>
          <p class="mb-1" v-if="customer.updated_at">
            <span class="me-2" style="font-weight: bold">Updated Date:</span>
            {{ formatedateTimes(customer.updated_at) }}
          </p>
        </v-col>
        <v-col md="4">
          <p class="mb-1">
            <span class="me-2" style="font-weight: bold">Company Phone No:</span>
            <ValueTemplate
              :value="customer.company_phone"
              title="Company Phone No."
            ></ValueTemplate>
          </p>
          <p class="mb-1">
            <span class="me-2" style="font-weight: bold">Created Date:</span>
            <!-- <ValueTemplate :value="customer.formatDate" title="Company Phone No."></ValueTemplate> -->
            {{ formatedateTimes(customer.added_at) }}
          </p>
        </v-col>
      </v-flex>
    </v-row>

    <v-row>
      <v-progress-linear
        indeterminate
        height="6"
        rounded
        v-if="pageLoading"
        class="position-absolute"
        color="blue darken-4"
      ></v-progress-linear>
      <v-col md="12">
        <v-container fluid>
          <v-tabs
            v-model="customerTab"
            background-color="transparent"
            color="blue"
            class="custom-tab-transparent"
            active-class="blue darken-4 text-white"
            hide-slider
          >
            <v-tab href="#overview"> OVERVIEW </v-tab>
            <v-tab href="#contactpersons">
              CONTACT PERSONS ({{ contactpersonsArr?.length }})
            </v-tab>
            <v-tab href="#attachements"> <v-icon>mdi-file</v-icon> ATTACHMENTS </v-tab>
            <v-tab href="#products"> PRODUCTS ({{ productsArr?.length }}) </v-tab>
            <v-tab href="#purchase"> PO ({{ purchaseArr?.length }}) </v-tab>
            <v-tab href="#logos"> LOGS ({{ LogsArr?.length }}) </v-tab>
            <v-tabs-items v-model="customerTab">
              <v-tab-item value="overview">
                <v-col md="8" class="my-auto px-0 py-3">
                  <div>
                    <p style="font-size: 16px; font-weight: 600" color="#0D47A1">
                      Business description: Create New Supplier
                    </p>
                  </div>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        ><span style="font-size: 16px; font-weight: 600" color="#0D47A1"
                          >Address</span
                        ><span align="end"><v-icon>schedule</v-icon></span>
                      </v-expansion-panel-header>
                      <v-divider class="my-auto py-0"></v-divider>
                      <v-expansion-panel-content>
                        <p class="my-auto py-2">
                          <v-icon color="blue darken-4">location_on</v-icon
                          >{{ customer.address_line_1 }}
                        </p>
                        <div class="my-auto py-0 px-7">
                          <p class="my-auto py-0">{{ customer.address_line_2 }}</p>
                          <p>{{ customer.postal_code }}</p>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-tab-item>
              <v-tab-item value="contactpersons">
                <SupplierContact class="py-3" :cpersons="contactpersonsArr"></SupplierContact>
              </v-tab-item>
              <v-tab-item value="attachements">
                <v-col md="12" class="py-3 px-0">
                  <!-- <FileUpload
                    id="file"
                    :directionColumn="false"
                    accept="image"
                    allowAddMore
                    v-model="customer.code"
                  ></FileUpload> -->
                  <Files
                    class="mx-12"
                    type-text="Supplier Attachments"
                    type="supplier"
                    :type-uuid="23"
                  ></Files>
                </v-col>
              </v-tab-item>
              <v-tab-item value="products">
                <ExpiredTemplate reload="reload" :products="productsArr"></ExpiredTemplate>
              </v-tab-item>
              <v-tab-item value="purchase">
                <PurchaseTemplate reload="reload" :purchaseArr="purchaseArr"></PurchaseTemplate>
              </v-tab-item>
              <v-tab-item value="logos">
                <SupplierLogsTemplate reload="reload" :logos="LogsArr"></SupplierLogsTemplate>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-container>
      </v-col>
    </v-row>
    <OrderTemplate
      v-model="order"
      :customer-id="customerId"
      :dialog="orderDialog"
      v-on:close="orderDialog = false"
      v-on:save="getOrders()"
      v-on:collect:voucher="getAvailableVouchers"
      v-on:claim:voucher="getCollectedVouchers"
    ></OrderTemplate>
    <DeleteTemplate
      type="Order"
      :delete-text="deleteText"
      delete-note="All transactions of this order will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="getOrders()"
    ></DeleteTemplate>
    <DeleteTemplate
      type="Customer"
      :delete-text="cDeleteText"
      delete-note="All transactions of this customer will also be deleted."
      :delete-dialog="cDeleteDialog"
      :delete-endpoint="cDeleteEndpoint"
      v-on:close="cDeleteDialog = false"
      v-on:delete:success="goBack()"
    ></DeleteTemplate>
    <CollectTemplate
      :dialog="collectDialog"
      :order-id="cOrderId"
      :order-code="cOrderCode"
      :vouchers="cVouchers"
      v-on:close="vCollected()"
      v-on:collected="vCollected()"
    ></CollectTemplate>
    <RedeemTemplate
      :dialog="redeemDialog"
      :order-id="rOrderId"
      :order-code="rOrderCode"
      :vouchers="rVouchers"
      v-on:close="vRedeemed()"
      v-on:redeemed="vRedeemed()"
    ></RedeemTemplate>
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
import moment from "moment-timezone";

import OrderTemplate from "@/view/components/OrderTemplate";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ImageTemplate from "@/view/components/Image";
import CollectTemplate from "@/view/components/CollectVoucherTemplate";
import RedeemTemplate from "@/view/components/RedeemVoucherTemplate";
import SupplierContact from "@/view/components/SupplierContact";
import ValueTemplate from "@/view/components/ValueTemplate";
// import FileUpload from "@/view/components/FileUpload";
import Files from "@/view/components/Files";
import ExpiredTemplate from "@/view/components/ExpiredVoucherTemplate";
import PurchaseTemplate from "@/view/components/PurchaseTemplate";
import { formatMoney } from "accounting-js";

import SupplierLogsTemplate from "@/view/components/SupplierLogsTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_SUPPLIER } from "@/core/lib/supplier.lib";
import { GET_ORDERS } from "@/core/lib/order.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import {
  COMMON_EVENT_BUS,
  GET_AVAILABLE_VOUCHERS,
  GET_COLLECTED_VOUCHERS,
} from "@/core/lib/common.lib";
import { format } from "date-fns";

export default {
  name: "customer-detail",
  title: "Detail Supplier",
  data() {
    return {
      supplierDetails: [],
      purchaseArr: [],
      speedDial: [],
      contactpersonsArr: [],
      productsArr: [],
      LogsArr: [],
      cLoading: false,
      rLoading: false,
      cDeleteText: null,
      cDeleteDialog: false,
      cDeleteEndpoint: null,
      deleteDialog: false,
      deleteEndpoint: null,
      deleteText: null,
      pageLoading: true,
      orderDialog: false,
      collectDialog: false,
      redeemDialog: false,
      customerId: null,
      customerTab: "orders",
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      search: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },
      customer: {
        barcode: null,
        title: null,
        first_name: null,
        last_name: null,
        full_name: null,
        email: null,
        date_of_birth: null,
        phone_number: null,
        address_line_1: null,
        address_line_2: null,
        unit_number: null,
        postal_code: null,
        country: null,
        total_orders: null,
        total_vcollected: null,
        total_vredeemed: null,
        total_vexpired: null,
        added_by: null,
        added_at: null,
        updated_by: null,
        updated_at: null,
      },
      orders: [],
      cVouchers: [],
      cOrderId: 0,
      cOrderCode: null,
      rOrderId: 0,
      rVouchers: [],
      rOrderCode: null,
    };
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
  },
  methods: {
    formatMoney(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    routeToUpdate(id) {
      // alert(id)
      this.$router.push({
        name: "supplier-update",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.getOrders();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.getOrders();
    },
    deleteCustomerConfirm() {
      this.cDeleteText = this.customer.barcode;
      this.cDeleteDialog = true;
      this.cDeleteEndpoint = `customer/${this.customerId}`;
    },
    deleteConfirm(row) {
      this.deleteDialog = true;
      this.deleteText = row.barcode;
      this.deleteEndpoint = `customer/${this.customerId}/order/${row.id}`;
    },
    clearVars() {
      this.orderDialog = false;
      this.deleteDialog = false;
      this.deleteText = null;
      this.deleteEndpoint = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    pageTitle() {
      if (this.customer.full_name) {
        return this.customer.full_name;
      }
      return "Detail Supplier";
    },
    // routeToUpdate(row) {
    //   this.order = {
    //     id: row.id,
    //     date: row.date,
    //     time: row.time,
    //     bill_no: row.bill_no,
    //     amount: row.amount,
    //     voucher: row.voucher,
    //   };
    //   this.orderDialog = true;
    // },
    async getEmployee() {
      const _this = this;
      // alert("hello"+this.customerId)
      try {
        // const customer = await GET_CUSTOMER(_this.customerId);
        const customer = await GET_SUPPLIER(this.customerId);
        _this.getOrders();
        _this.customer = customer.supplier;
        this.contactpersonsArr = customer.persons;
        this.productsArr = customer.products;
        this.purchaseArr = customer.purchase;
        this.LogsArr = customer.logs;
        console.log("_this.customer");
        console.log(_this.LogsArr);

        _this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Customer", disabled: true },
          { text: "Detail", disabled: true },
          { text: customer.barcode, disabled: true },
        ]);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getOrders() {
      const _this = this;
      const form = {
        current_page: _this.currentPage,
        search: _this.search,
      };
      try {
        _this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_ORDERS(
          _this.customerId,
          form
        );
        _this.clearVars();
        _this.orders = rows;
        _this.pageLimit = totalPages;
        _this.showingFrom = showingFrom;
        _this.showingTo = showingTo;
        _this.totalRows = totalRows;
        COMMON_EVENT_BUS.$emit("orders:loaded", true);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Detail", disabled: true },
    ]);

    /* const { id, row } = this.$route.params;
    //console.log(this.$route.params);
    if (id && row) {
      this.speedDial = row;
      this.customerId = toSafeInteger(id);
      this.getEmployee();
      
    } else {
      this.goBack();
    } */
  },
  created() {
    const { id, row } = this.$route.params;
    console.log(this.$route.params);
    if (id && row) {
      this.speedDial = row;
      this.customerId = toSafeInteger(id);
      this.getEmployee();
    } else {
      this.goBack();
    }
  },
  components: {
    ValueTemplate,
    ExpiredTemplate,
    CollectTemplate,
    RedeemTemplate,
    DeleteTemplate,
    OrderTemplate,
    ImageTemplate,
    SupplierContact,
    Files,
    SupplierLogsTemplate,
    PurchaseTemplate,
  },
};
</script>

<style>
.detail_image .v-image.v-responsive.white.lighten-2.theme--light.border-light-grey.max-width-100px {
  height: 90px;
  width: 90px;
}
</style>
