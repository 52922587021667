import ApiService from "@/core/services/api.service";

// ---------CREATE SUPPLIER-------------
export const CREATE_SUPPLIER = (params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.post("supplier/add-edit", params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
// ---------GET SUPPLIER-------------
export const GET_SUPPLIER = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query(`supplier/detail/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GET_CUSTOMER = (id) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.get(`supplier/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// ---------UPDATE SUPPLIER-------------
export const UPDATE_SUPPLIER = (id, params) => {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.put(`supplier/add-edit/${id}`, params)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
